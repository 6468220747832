<template>
<v-menu
  v-model="menu"
  :close-on-content-click="false"
  :nudge-right="40"
  transition="fade-transition"
  offset-y
  min-width="auto"
>
  <template v-slot:activator="{ on, attrs }">
    <v-text-field
      v-model="inputValue"
      :clearable="clearable"
      :dense="dense"
      :hint="hint"
      :label="label"
      :persistent-hint="persistentHint"
      :prepend-icon="CALENDAR_ICON"
      :rules="rules"
      readonly
      v-bind="attrs"
      v-on="!readonly ? on : null"
    />
  </template>
  <v-date-picker
    v-model="inputValue"
    @input="menu = false"
    @change="$emit('change')"
    no-title
    show-current
  />
</v-menu>
</template>

<script>
import { ICONS } from '../../constants'

export default {
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    dense: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
    },
    hint: {
      type: String,
      default: '',
    },
    persistentHint: Boolean,
  },
  data: () => ({
    menu: false
  }),
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.CALENDAR_ICON = ICONS.CALENDAR
  },
}
</script>
