<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    :fullscreen="isXsBreakpoint"
    @click:outside="close()"
    @keydown.esc="close()"
    max-width="600px"
  >
    <v-card
      class="pb-2 px-0"
      tile
    >
      <CardToolbar
        :disable-add-event-group="!valid"
        @add-event-group="addEventGroup()"
        @close="close()"
        button-mode
        class="mx-3 my-0 pt-4"
        include-add-event-group
        include-close
        title="Add Event Group"
      />
      <v-card-text class="pb-2 pt-8 px-4">
        <v-container class="px-2 py-0">
          <v-form
            v-model="valid"
            @submit.prevent
          >
            <v-row>
              <v-col>
                <v-select
                  v-model="selectedEventGroup"
                  :items="eventGroups"
                  @change="handleEventGroupChange"
                  class="mx-1"
                  color="primary"
                  dense
                  item-text="eventGroupType"
                  item-value="eventGroupType"
                  label="Event Group"
                  return-object
                />
              </v-col>
            </v-row>

            <v-row class="mb-4">
              <v-col>
                <text-field-date-picker
                  v-model="payload.start"
                  :readonly="false"
                  dense
                  hint="The date of the first event in this group."
                  label="Group Start Date"
                  persistent-hint
                />
              </v-col>
            </v-row>

            <v-row class="mb-4">
              <v-col>
                <v-text-field
                  v-model="payload.titlePrefix"
                  class="mx-1"
                  clearable
                  color="primary"
                  dense
                  hint="Prefixes the title of each event in the group with this text."
                  label="Event Title Prefix"
                  :placeholder="selectedEventGroup.titlePrefix"
                  persistent-hint
                />
              </v-col>
            </v-row>

            <v-row class="mb-4">
              <v-col>
                <v-textarea
                  v-model="payload.descriptionTemplate"
                  :placeholder="selectedEventGroup.descriptionTemplate"
                  :rules="[rules.length250]"
                  auto-grow
                  class="mx-1"
                  clearable
                  color="primary"
                  counter="250"
                  dense
                  hint="Sets the description of each event in the group with this text."
                  label="Description Template"
                  persistent-hint
                  row-height="20"
                  rows="3"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="text-overline">Events</div>
                <div class="text-body-2">The following events will be added to the calendar. The <strong>Day Offset</strong> for the first event is the number of days after the <strong>Group Start Date</strong>, for every other event it is the number of days after the previous event.</div>
              </v-col>
            </v-row>

            <v-row
              v-for="(eventType, i) in selectedEventGroup.titleList"
              :key="i"
            >
              <v-col
                cols="3" sm="2" 
                dense
              >
                <v-text-field
                  v-model="payload.dayOffsetList[i]"
                  @blur="handleDeleteDayOffset(i)"
                  @keypress="assertNumber($event)"
                  :counter="3"
                  class="mx-1"
                  color="primary"
                  dense
                  label="Day Offset"
                />
              </v-col>
              <v-col
                cols="7" sm="8"
                dense
              >
                <v-text-field
                  @input="setEventTitle($event, i)"
                  :counter="50"
                  :rules="[rules.required, rules.length50]"
                  :value="displayEventTitle(i)"
                  class="mx-1"
                  color="primary"
                  dense
                  label="Event Title**"
                />
              </v-col>
              <v-col
                cols="2"
                dense
              >
                <v-card
                  :color="`${EVENT_GROUP_COLOR[payload.eventGroupType]} ${EVENT_GROUP_COLOR_MODIFIER[payload.typeList[i]]}`"
                  class="d-flex align-center justify-center"
                  :dark="EVENT_GROUP_DARK[payload.typeList[i]]"
                  height="100%"
                  width="100%"
                  flat
                >
                  <v-icon>{{ COLOR_ICON }}</v-icon>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <CardToolbar
        v-if="scrollable"
        :disable-add-event-group="!valid"
        @add-event-group="$emit('add-event-group', payload)"
        @close="close()"
        button-mode
        class="mx-3 my-0 pb-4"
        include-add-event-group
        include-close
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { EVENT_GROUP_COLOR_MODIFIER, EVENT_GROUP_COLOR, EVENT_GROUP_DARK, ICONS, } from '../../../constants'
import CalendarEventFactory from '../../../models/CalendarEventFactory'

export default {
  components: {
    CardToolbar: () => import('../toolbars/CardToolbar.vue'),
    RowSubheader: () => import('../RowSubheader.vue'),
    TextFieldDatePicker: () => import('../../input/TextFieldDatePicker.vue'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
  },
  data: () => ({
    eventGroups: [],        // Set to default values on mount and dialog close.
    rules: {
      required: v => !!v || 'Value is required.',
      length50: v => (v || '').length <= 50 || 'Must have 50 characters or fewer.',
      length250: v => (v || '').length <= 250 || 'Must have 250 characters or fewer.',
    },
    scrollable: false,
    selectedEventGroup: {}, // Which event set is selected in the dialog window.
    valid: false,
    payload: {},            // The actual values set in the dialog window.
  }),
  computed: {
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    isXsBreakpoint () {
      return this.breakpoint === 'xs'
    },
  },
  methods: {
    addEventGroup () {
      this.$emit('add-event-group', this.payload)
      this.close()
    },
    assertNumber ($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $keyCode;
      
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault()
      }
    },
    close () {
      this.$emit('update:dialog', false)
      this.resetEventGroups()
    },
    displayEventTitle (index) {
      if (this.payload.titlePrefix) {
        if (this.payload.titleList[index]) {
          return this.payload.titlePrefix + this.payload.titleList[index]
        }
        return this.payload.titlePrefix
      }
      return this.payload.titleList[index]
    },
    handleEventGroupChange () {
      this.payload = cloneDeep(this.selectedEventGroup)
      this.payload.titlePrefix = ''
      this.payload.descriptionTemplate = ''
      this.payload.eventGroupType = this.selectedEventGroup.eventGroupType
    },
    handleDeleteDayOffset (index) {
      if (this.payload.dayOffsetList[index].length === 0) {
        this.payload.dayOffsetList[index] = 0
        this.$forceUpdate()
      }
    },
    initTemplateConstants () {
      this.EVENT_GROUP_COLOR = EVENT_GROUP_COLOR
      this.EVENT_GROUP_COLOR_MODIFIER = EVENT_GROUP_COLOR_MODIFIER
      this.EVENT_GROUP_DARK = EVENT_GROUP_DARK
      this.COLOR_ICON = ICONS.COLOR
    },
    resetEventGroups () {
      this.eventGroups = [
        CalendarEventFactory.getDefaultMagazineEventGroups(),
        CalendarEventFactory.getDefaultMaturityLetterEventGroups(),
        CalendarEventFactory.getDefaultSeasonalEMessageEventGroups(),
        CalendarEventFactory.getDefaultThankYouCardsEventGroups(),
      ]

      this.selectedEventGroup = this.eventGroups[0]
      this.handleEventGroupChange()
    },
    setEventTitle (event, index) {
      this.payload.titleList[index] = event.replace(this.payload.titlePrefix, '')
    },
  },
  beforeUpdate () {
    this.$nextTick(() => {
      const element = document.getElementsByClassName('v-dialog--active')[0]
      this.scrollable = !!element && element.scrollHeight > element.clientHeight
    })
  },
  mounted () {
    this.resetEventGroups()
  },
  created () {
    this.initTemplateConstants()
  },
}
</script>