<template>
  <v-card
    class="mx-auto"
    loading
    max-width="600px"
  >
    <card-toolbar
      :icon="DOWNLOAD_ICON"
      :title="text"
      class="mt-4 mb-3"
    />
  </v-card>
</template>

<script>
import { ICONS } from '../../../constants'

export default {
  components: {
    CardToolbar: () => import('../toolbars/CardToolbar.vue'),
  },
  props: {
    text: {
      type: String,
    },
  },
  methods: {
    initTemplateConstants () {
      this.DOWNLOAD_ICON = ICONS.DOWNLOAD
    }
  },
  created () {
    this.initTemplateConstants()
  }
}
</script>
