
export const ACCESS_TOKEN = 'icare_access_token'
export const REFRESH_TOKEN = 'icare_refresh_token'

export const COLUMNS = 'columns'
export const DATA = 'data'
export const METADATA = 'metadata'
export const PRIMARY_KEY = 'PRIMARY_KEY'
export const SAVED_DATA = 'saved_data'
export const SELECT = 'SELECT'

export const YES_OR_NO = ['Y', 'N',]

export const ACTIONS = {
  ADD: 'add',
  CREATE: 'create',
  DELETE: 'delete',
  DETAIL: 'detail',
  EDIT: 'edit',
  FILE_UPLOAD: 'file_upload',
  GET: 'get',
  LIST: 'list',
  NEW: 'new',
  REFRESH: 'refresh',
  UPDATE: 'update',
  OPERATION: 'operation',
}

export const COLOR = {
  ERROR: 'error',
  INFO: 'info',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
  WHITE: 'white',
}

export const DEFAULT_USER = { username: null, email: null, id: null, }

export const DIALOGS = {
  AGENTS:'AgentsDialog',
  CONFIRMATION: 'confirmation',
  CRUD: 'crud',
  EVENT: 'EventDialog',
  EVENT_GROUP: 'EventGroupsDialog',
  VALID_TABLES:'ValidTablesDialog' ,
}

export const EVENT_TITLE = {
  // Thank You Cards
  // Maturity Letters
  DATA_SWEEP: 'Data Sweep',
  EMAIL_NOTIFICATION: 'Email Notification',
  RESPONSE_DATE: 'Response Date',
  FILE_TO_PRINTER: 'File To Printer',
  MAIL_DATE: 'Mail Date',

  // Magazine
  COUNTS_TO_TRANSCON: 'Counts to TransCon',
  DATA_FILE_TO_TRANSCON: 'Data File to TransCon',
  MAILING_BEGINS: 'Mailing Begins',

  // Seasonal E-Message
  DATA_FILE_DUE: 'Data File Due',
  DEPLOYMENT: 'Deployment',
}

export const EVENT_GROUPS = {
  MAGAZINES: 'Magazines',
  MATURITY_LETTERS: 'Maturity Letters',
  SEASONAL_E_MESSAGE: 'Seasonal E-Message',
  THANK_YOU_CARDS: 'Thank You Cards',
}

export const EVENT_GROUP_COLOR = {
  [EVENT_GROUPS.MAGAZINES]: 'purple',
  [EVENT_GROUPS.MATURITY_LETTERS]: 'light-blue',
  [EVENT_GROUPS.SEASONAL_E_MESSAGE]: 'green',
  [EVENT_GROUPS.THANK_YOU_CARDS]: 'red',
}

export const EVENT_GROUP_DARK = {
  // Thank You Cards
  // Maturity Letters
  [EVENT_TITLE.DATA_SWEEP]: false,
  [EVENT_TITLE.EMAIL_NOTIFICATION]: false,
  [EVENT_TITLE.RESPONSE_DATE]: true,
  [EVENT_TITLE.FILE_TO_PRINTER]: true,
  [EVENT_TITLE.MAIL_DATE]: true,

  // Magazine
  [EVENT_TITLE.COUNTS_TO_TRANSCON]: false,
  [EVENT_TITLE.DATA_FILE_TO_TRANSCON]: false,
  [EVENT_TITLE.MAILING_BEGINS]: true,

  // Seasonal E-Message
  [EVENT_TITLE.DATA_FILE_DUE]: false,
  [EVENT_TITLE.DEPLOYMENT]: false,
}

export const EVENT_GROUP_COLOR_MODIFIER = {
  // Thank You Cards
  // Maturity Letters
  [EVENT_TITLE.DATA_SWEEP]: 'lighten-4',
  [EVENT_TITLE.EMAIL_NOTIFICATION]: 'lighten-2',
  [EVENT_TITLE.RESPONSE_DATE]: '',
  [EVENT_TITLE.FILE_TO_PRINTER]: 'darken-2',
  [EVENT_TITLE.MAIL_DATE]: 'darken-4',

  // Magazine
  [EVENT_TITLE.COUNTS_TO_TRANSCON]: 'lighten-4',
  [EVENT_TITLE.DATA_FILE_TO_TRANSCON]: 'lighten-2',
  [EVENT_TITLE.MAILING_BEGINS]: '',

  // Seasonal E-Message
  [EVENT_TITLE.DATA_FILE_DUE]: 'lighten-4',
  [EVENT_TITLE.DEPLOYMENT]: 'lighten-2',
}

export const EXPANDS = {
  AGENT_VUE_AGENT: 'AgentAgentExpand',
  AGENT_VUE_AGENT_ALIAS: 'AgentAliasExpand',
  AGENT_VUE_AGENT_PROFILE: 'AgentProfileExpand',
  AGENT_VUE_BUSINESS_ADDRESS: 'AgentBusinessAddressExpand' ,
  AGENT_VUE_SOCIAL_MEDIA: 'AgentSocialMediaExpand',
  AGENT_VUE_TEAM_ASSN: 'AgentTeamAssnExpand',
  CLIENT_VUE_ADDRESS: 'ClientAddressExpand',
  CLIENT_VUE_AGENT_ASSN: 'ClientAgentAssnExpand',
  CLIENT_VUE_CLIENT: 'ClientClientExpand',
  CLIENT_VUE_CONTACT: 'ClientContactExpand',
  CLIENT_VUE_PROGRAM_CONTROL: 'ClientProgramControlExpand',
  CLIENT_VUE_MORTGAGE_DEAL_ASSN: 'ClientMortgageDealAssnExpand',
  MORTGAGE_DEAL_VUE_MORTGAGE_DEAL: 'MortgageDealMortgageDealExpand',
  MORTGAGE_DEAL_VUE_MORTGAGE: 'MortgageDealMortgageExpand', 
  MORTGAGE_DEAL_VUE_PROPERTY: 'MortgageDealPropertyExpand',
  MORTGAGE_DEAL_VUE_PROGRAM_CONTROL: 'MortgageDealProgramControlExpand',
  MORTGAGE_DEAL_VUE_COMMENT: 'MortgageDealCommentExpand', 
  MORTGAGE_DEAL_VUE_AGENT_ASSN: 'MortgageDealAgentAssnExpand',
  MORTGAGE_DEAL_VUE_CLIENT_ASSN: 'MortgageDealClientAssnExpand',
}

export const ICONS = {
  ACCOUNT: 'mdi-account',
  ACCOUNT_SETTINGS: 'mdi-badge-account',
  ADD_ACCOUNT: 'mdi-account-plus',
  ADD_AGENT: 'mdi-account-plus',
  ADD_COMMENT: 'mdi-comment-plus',
  ADD_ENTRY: 'mdi-table-plus',
  ADD_GENERIC: 'mdi-plus',
  ADD_TABLE: 'mdi-table-plus',
  AGENT: 'mdi-account-tie',
  AGENT_BOX: 'mdi-account-box',
  ALERT: 'mdi-alert-circle',
  BACK: 'mdi-arrow-left',
  BUG: 'mdi-bug',
  CALENDAR: 'mdi-calendar',
  CALENDAR_UPCOMING_EVENT: 'mdi-calendar-clock',
  CALENDAR_EVENT_UPDATE: 'mdi-calendar-refresh',
  CALENDAR_MULTIPLE: 'mdi-calendar-multiple',
  CANCEL: 'mdi-cancel',
  CHECK: 'mdi-check',
  CLIENTS: 'mdi-account-group',
  CLOSE: 'mdi-close',
  COLLAPSE: 'mdi-arrow-collapse-vertical',
  COLOR: 'mdi-palette',
  COMMENT: 'mdi-comment-text',
  CURRENCY: 'mdi-currency-usd',
  DATA: 'mdi-database',
  DELETE: 'mdi-delete',
  DISABLED: 'mdi-alpha-d-box-outline',
  DOT_MENU: 'mdi-dots-vertical',
  DOWNLOAD: 'mdi-download',
  DROPDOWN: 'mdi-form-dropdown',
  EDIT: 'mdi-pencil',
  EMAIL: 'mdi-at',
  EXPAND: 'mdi-arrow-expand-vertical',
  FACEBOOK: 'mdi-facebook',
  FILE: 'mdi-file-delimited-outline',
  FORWARD: 'mdi-arrow-right',
  GOOGLE_MY_BUSINESS: 'mdi-google-my-business',
  HELP: 'mdi-help-circle-outline',
  HIDE: 'mdi-eye-off',
  INFO: 'mdi-information-outline',
  INSTAGRAM: 'mdi-instagram',
  LINKED_IN: 'mdi-linkedin',
  LOCK: 'mdi-lock',
  MONITOR: 'mdi-monitor-dashboard',
  MORTGAGE_DEAL: 'mdi-handshake-outline',
  NOT_FOUND: 'mdi-alert-decagram-outline',
  PASSWORD_FORGOT: 'mdi-lock-question',
  PASSWORD_RESET: 'mdi-lock-reset',
  REFRESH: 'mdi-refresh',
  REPORTS: 'mdi-file-document-outline',
  SAVE: 'mdi-content-save-outline',
  SEARCH: 'mdi-magnify',
  SETTINGS: 'mdi-application-cog',
  SIGN_OUT: 'mdi-logout',
  SHOW: 'mdi-eye',
  STALE_EVENT: 'mdi-exclamation-thick',
  TABLE: 'mdi-table',
  TWITTER: 'mdi-twitter',
  UPLOAD: 'mdi-upload',
  UPLOAD_TO_DATABASE: 'mdi-database-arrow-up-outline',
  WARNING: 'mdi-alert',
  WRENCH: 'mdi-wrench',
  YOUTUBE: 'mdi-youtube',
}

export const MESSAGE_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
}

export const REPORT_TYPES = {
  DEFAULT: "default",
  PARAMETER: "parameter",
}

export const RESULT = {
  SUCCESS: 'success',
}

export const ROUTES = {
  ACTIVATE: '/activate',
  ACCOUNT: '/dashboard/account',
  AGENTS: '/dashboard/agents',
  CLIENTS: '/dashboard/clients',
  DASHBOARD: '/dashboard',
  FORGOT_PASSWORD: '/password/forgot',
  LOAD_DATA: '/dashboard/load-data',
  MAILING_SCHEDULE: '/dashboard/mailing-schedule',
  MORTGAGE_DEALS: '/dashboard/mortgage-deals',
  NOT_FOUND: '*',
  REPORTS: '/dashboard/reports',
  RESET_PASSWORD: '/password/reset/confirm',
  ROOT: '/',
  SIGN_IN: '/signin',
  VALID_TABLES: '/dashboard/valid-tables',
}

export const SOCIAL_MEDIA_ICON_MAP = {
  'Facebook': ICONS.FACEBOOK,
  'Google My Business': ICONS.GOOGLE_MY_BUSINESS,
  'Instagram': ICONS.INSTAGRAM,
  'LinkedIn': ICONS.LINKED_IN,
  'Twitter': ICONS.TWITTER,
  'Youtube': ICONS.YOUTUBE,
  'facebook': ICONS.FACEBOOK,
  'google_my_business': ICONS.GOOGLE_MY_BUSINESS,
  'instagram': ICONS.INSTAGRAM,
  'linked_in': ICONS.LINKED_IN,
  'twitter': ICONS.TWITTER,
  'youtube': ICONS.YOUTUBE,
}

export const TEXT_COLOR = {
  ERROR: 'error--text',
  INFO: 'info--text',
  PRIMARY: 'primary--text',
  SECONDARY: 'secondary--text',
  SUCCESS: 'success--text',
  DEFAULT: 'grey--text text--darken-1',
}

export const VALID_TABLES = {
  AGENT_TEAM: 'agent_team',
  AGENT_PROFILE: 'valid_agent_profile',
  COMMENT_TYPE: 'valid_comment_type',
  LANGUAGE: 'valid_language',
  MORTGAGE_DEAL_STATUS: 'valid_mortgage_deal_status',
  PROGRAM: 'valid_program',
  PROGRAM_ELEMENT: 'valid_program_element',
  PROGRAM_ELEMENT_FREQUENCY: 'valid_program_element_frequency',
  PROVINCE: 'valid_province',
  SOCIAL_MEDIA: 'valid_social_media',
}

export const VUES = {
  AGENT: 'Agent',
  AGENTS: 'Agents',
  CLIENT: 'Client',
  CLIENTS: 'Clients',
  DASHBOARD: 'Dashboard',
  LOAD_DATA: 'LoadData',
  LOAD_NEW_DATA: 'LoadNewData',
  LOAD_UPDATE_DATA: 'LoadUpdateData',
  MAILING_SCHEDULE: 'MailingSchedule',
  MORTGAGE_DEAL: 'MortgageDeal',
  MORTGAGE_DEALS: 'MortgageDeals',
  REPORTS: 'Reports',
  SIGN_IN: 'SignIn',
  VALID_TABLES: 'ValidTables',
}