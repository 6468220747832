import Repository from './repository'

import { ACTIONS } from '../../constants'
import { TYPE as AGENT_TYPE } from '../../models/dto/Agent'
import { TYPE as MORTGAGE_DEAL_TYPE } from '../../models/dto/MortgageDeal'
import { TYPE as OPERATION_TYPE, URL as OPERATION_URL } from '../../models/dto/Operation'
import { URL as AGENT_URL } from './agent.service'
import { URL as MORTGAGE_DEAL_URL } from './mortgage.deal.service'
import { includes } from 'lodash'

/**
 * Types permitted to be uploaded.
 */
export const CSV_UPLOAD_TYPES = Object.freeze([
  AGENT_TYPE.AGENT,
  MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL,
  MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT,
  OPERATION_TYPE.TRANSFER_MORTGAGE_DEAL,
])

/**
 * Actions permitted when uploading.
 */
export const CSV_ACTIONS = Object.freeze([
  ACTIONS.ADD,
  ACTIONS.UPDATE,
  ACTIONS.OPERATION,
])

/**
 * URLs associated with uploading CSV files. 
 */
const URL = Object.freeze({
  'CSV': 'upload/csv',
  [AGENT_TYPE.AGENT]: AGENT_URL[AGENT_TYPE.AGENT],
  [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL]: MORTGAGE_DEAL_URL[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL],
  [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT]: MORTGAGE_DEAL_URL[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT],
  [OPERATION_TYPE.TRANSFER_MORTGAGE_DEAL]: OPERATION_URL[OPERATION_TYPE.TRANSFER_MORTGAGE_DEAL]
})

class CSVService {

  getURL (type, action) {
    let url = `${URL.CSV}/${URL[type]}/`
    if (action !== ACTIONS.OPERATION) {
      url += `${action}/`
    }
    return url
  }

  uploadFile (type, action, formData, callback) {
    this.validate(type, action)
    return Repository.postWithFile(this.getURL(type, action), formData, callback,)
  }

  validate (type, action) {
    if (!includes(CSV_UPLOAD_TYPES, type)) {
      throw new TypeError(`Type, "${type}", does not have a CSV end-point implemented.`)
    }
    if (!includes(CSV_ACTIONS, action)) {
      throw new TypeError(`Action, "${action}", is not supported.`)
    }
  }

}

const csvService = new CSVService()
export default csvService