<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="8" class="pa-0">
      <!-- Display upload errors for file. -->
      <UploadFileErrors
        v-if="alert"
        @reset="resetFile()"
        :errors="errors"
        :title-text="`${ capitalizedSingularType } File Errors`"
      />
      <!-- Otherwise, display the default new data page. -->
      <v-card
        v-else
        class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
        max-width="600"
        outlined
      >
        <CardToolbar
          :back-button-route="LOAD_DATA_ROUTE"
          :back-button-tooltip="'Load Data'"
          button-mode
          :icon="UPLOAD_TO_DATABASE_ICON"
          include-back
          include-help
          :title="`New ${ capitalizedSingularType } Data`"
        />
        <HelpText :page="LOAD_NEW_DATA_VUE" />

        <v-card-text class="mt-n4">
          <p :class="`${ DEFAULT_TEXT } mb-2`">1. Download the CSV template formatted for {{ pluralType }}.</p>
          
          <v-btn
            block
            class="mb-4 mx-auto"
            @click="downloadCSV()"
            color="primary" 
            outlined
          >
            {{ csvDataType }}_data_template.csv
            <v-icon right>{{ DOWNLOAD_ICON }}</v-icon>
          </v-btn>
          
          <p :class="`${ DEFAULT_TEXT }`">2. Add {{ pluralType }} to the template (one per line).</p>
          
          <p :class="`${ DEFAULT_TEXT } mb-4`">3. Select a file to upload into the system.</p>

          <v-file-input
            v-model="file"
            @change="setFile"
            accept="text/csv"
            class="mb-4"
            :error-messages="errorMessages"
            label="Select a CSV file..."
            :prepend-icon="FILE_ICON"
            ref="input"
            :rules = rules
            validate-on-blur
            truncate-length="100"
          />

          <p :class="`${ DEFAULT_TEXT } mb-4`">4. Upload the file to the server.</p>
  
          <template v-if="!uploading">
            <v-btn
              @click="uploadCSV()"
              block
              class="mr-2 mb-4"
              color="primary"
              :disabled="disable"
              outlined
            >
              Upload CSV File
              <v-icon right>{{ UPLOAD_ICON }}</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-progress-linear
              v-model="progress"
              class="mt-2 mb-4"
              color="primary"
              height="36"
              rounded
            ></v-progress-linear>
          </template>

          <p :class="`${ DEFAULT_TEXT } mb-0`">5. You will receive an email when the file has finished processing.</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { includes, snakeCase } from 'lodash'

import { mapActions, mapGetters } from 'vuex'

import { CSV_UPLOAD_TYPES } from '../services/api/csv.service.js'
import CSVFileService from '../services/csv.file.service.js'
import UploadService from '../services/upload.service.js'
import { ACTIONS, ICONS, ROUTES, VUES, TEXT_COLOR, MESSAGE_TYPE, RESULT, } from '../constants'
import { capitalize,  plural, singular } from '../services/format.service.js'
import { isUtf8 } from '../services/utility.service.js'

export default {
  name: 'LoadNewData',
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    HelpText: () => import('../components/layout/HelpText.vue'),
    UploadFileErrors: () => import('../components/layout/UploadFileErrors.vue'),
  },
  data: () => ({
    csvDataType: '', // csv data-type used to configure the page
    disable: true,   // toggles the enable upload button

    csvFileService: null, // service to handle the data-type's file
    uploadService: null,  // service to handle the uploading of file

    file: {},          // file to be uploaded
    errorMessages: [], // error messages related to the file input element
    progress: 0,       // file upload progress
    
    hasValidCharacterEncoding: false, // Does the currently loaded file have a valid character encoding?

    rules: [ // rules for the file upload text field
      file => !!file || 'Required.',
      file => !file || file.size < 50_000_000 || 'File size should be less than 50 MB.',
      file => { return file === null ? false : !file || /text\/csv/.test(file.type) || 'File type should be csv.' },
    ],

    uploading: false,

    messages: [], // messages to display
    errors: [],   // errors to display on the error screen
    alert: false, // when enabled, renders the error screen
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    capitalizedSingularType() {
      return capitalize(this.singularType)
    },
    pluralType() {
      return plural(this.csvDataType)
    },
    singularType() {
      return singular(this.csvDataType)
    },
  },
  methods: {
    ...mapActions({
      enqueueMessage: 'message/enqueueMessage',
    }),
    downloadCSV() {
      const file = this.csvFileService.getFileTemplate()
      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(file)}`
      anchor.target = '_blank'
      anchor.download = `${this.csvDataType}_data_template.csv`
      anchor.click()
    },
    initTemplateConstants() {
      this.DOWNLOAD_ICON = ICONS.DOWNLOAD
      this.FILE_ICON = ICONS.FILE
      this.UPLOAD_ICON = ICONS.UPLOAD
      this.UPLOAD_TO_DATABASE_ICON = ICONS.UPLOAD_TO_DATABASE

      this.LOAD_DATA_ROUTE = ROUTES.LOAD_DATA

      this.LOAD_NEW_DATA_VUE = VUES.LOAD_NEW_DATA

      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT
    },
    resetFile() {
      this.alert = false
      this.setFile(null)
    },
    async setFile(file) {
      this.file = file
      this.progress = 0
    },
    uploadCSV() {
      this.csvFileService.validate(this.file)
        .then(result => {
          if (result.status === RESULT.SUCCESS) {
            // Callback that will be used to update file upload progress bar.
            const progressCallback = progressEvent => {
              this.progress = Math.round((100 * progressEvent.loaded) / progressEvent.total)
            }

            this.uploading = true

            this.uploadService.upload(this.file, this.user.id, progressCallback)
              .then(response => {
                this.enqueueMessage({ message: response.message, action: ACTIONS.FILE_UPLOAD, type: MESSAGE_TYPE.SUCCESS })
              })
              .catch(error => {
                this.enqueueMessage({ error, data: {}, dataType: this.csvDataType, action: ACTIONS.FILE_UPLOAD, type: MESSAGE_TYPE.ERROR })
              })
              .finally(() => {
                this.uploading = false
                this.resetFile()
              })
          } else {
            this.errors = result.errors
            this.alert = true
          }
        })
        .catch(error => {
          this.resetFile()
          throw error
        })
    },
  },
  watch: {
    // When the file changes, validate it. Only enable the upload button for valid files.
    async file() {
      this.hasValidCharacterEncoding = !!this.file ? await isUtf8(this.file) : true
      this.errorMessages = this.hasValidCharacterEncoding ? [] : 'CSV file must be saved with UTF-8 character-encoding.' 
      this.disable = this.file === null || this.file.size >= 50_000_000 || !/text\/csv/.test(this.file.type) || !this.hasValidCharacterEncoding
    }
  },
  created() {
    this.initTemplateConstants()
  },
  mounted() {
    this.csvDataType = snakeCase(this.$route.params.id)
    if (!includes(CSV_UPLOAD_TYPES, this.csvDataType)) {
      throw new TypeError(`"${this.csvDataType}" is an unknown data type`)
    }
    
    this.uploadService = new UploadService(this.csvDataType, ACTIONS.ADD)
    this.csvFileService = new CSVFileService(this.csvDataType, ACTIONS.ADD)
  },
}
</script>