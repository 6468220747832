<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    :fullscreen="isXsBreakpoint"
    @click:outside="close()"
    @keydown.esc="close()"
    max-width="600px"
  >
    <v-card
      class="pb-2 px-0"
      tile
    >
      <CardToolbar
        :disable-add="!valid"
        :disable-update-event="!valid"
        :include-add="!isEditing"
        :include-delete="isEditing"
        :include-update-event="isEditing"
        :title="dialogTitle"
        @add-data="addEvent"
        @close="close()"
        @delete-data="deleteEvent"
        @update-event-data="updateEvent"
        button-mode
        class="mx-3 my-0 pt-4"
        include-close
      />
      <v-card-text class="pb-2 pt-8 px-4">
        <v-container class="px-2 py-0">
          <v-form
            v-model="valid"
            @submit.prevent
          >
            <v-row>
              <v-col>
                <v-select
                  v-model="selectedEventGroup"
                  :items="eventGroups"
                  @change="handleEventGroupChange"
                  class="mx-1"
                  color="primary"
                  dense
                  item-text="eventGroupType"
                  item-value="eventGroupType"
                  label="Event Group"
                  return-object
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedEventType"
                  :items="payload.typeList"
                  @change="handleEventTypeChange"
                  class="mx-1"
                  color="primary"
                  dense
                  item-text="type"
                  item-value="type"
                  label="Event Type"
                  return-object
                />
              </v-col>
            </v-row>

            <v-row class="mb-4">
              <v-col>
                <text-field-date-picker
                  v-model="payload.start"
                  :readonly="false"
                  dense
                  hint="Date this event will occur on."
                  label="Event Date"
                  persistent-hint
                />
              </v-col>
            </v-row>

            <v-row class="mb-4">
              <v-col>
                <v-text-field
                  v-model="payload.name"
                  :counter="50"
                  :rules="[rules.required, rules.length50]"
                  class="mx-1"
                  clearable
                  color="primary"
                  dense
                  hint="Title of the event."
                  label="Event Title**"
                  persistent-hint
                />
              </v-col>
            </v-row>

            <v-row class="mb-4">
              <v-col>
                <v-textarea
                  v-model="payload.description"
                  :rules="[rules.length250]"
                  auto-grow
                  class="mx-1"
                  clearable
                  color="primary"
                  counter="250"
                  dense
                  hint="Description containing the details of the event."
                  label="Description"
                  persistent-hint
                  row-height="20"
                  rows="3"
                />
              </v-col>
            </v-row>

            <v-row class="mb-4">
              <v-col cols="12" class="mb-0 pb-0">
                <span class="text-subtitle-1">Event Colour</span>
              </v-col>
              <v-col v-for="(type, i) in payload.typeList" :key="type">
                <v-card
                  :color="`${EVENT_GROUP_COLOR[payload.eventGroupType]} ${EVENT_GROUP_COLOR_MODIFIER[payload.typeList[i]]}`"
                  :dark="EVENT_GROUP_DARK[payload.typeList[i]]"
                  @click="selectColor(i)"
                  :class="`${selectedColor === i ? 'mt-0' : 'mt-2'} pa-2 d-flex align-center justify-center`"
                  flat
                  height="100%"
                  style="cursor: pointer;"
                  width="100%"
                >
                  <v-icon
                    v-if="selectedColor === i"
                    left
                    v-text="'mdi-check'"
                  />
                  <span class="text-caption text-center">Example Text</span>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <CardToolbar
        v-if="scrollable"
        :disable-add="!valid"
        :disable-update-event="!valid"
        :include-add="!isEditing"
        :include-delete="isEditing"
        :include-update-event="isEditing"
        @add-data="addEvent"
        @close="close()"
        @delete-data="deleteEvent"
        @update-event-data="updateEvent"
        button-mode
        class="mx-3 my-0 pb-4"
        include-close
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { EVENT_GROUP_COLOR_MODIFIER, EVENT_GROUP_COLOR, EVENT_GROUP_DARK, ICONS, EVENT_GROUPS, } from '../../../constants'
import CalendarEventFactory from '../../../models/CalendarEventFactory'
import { today } from '../../../services/utility.service'

export default {
  components: {
    CardToolbar: () => import('../toolbars/CardToolbar.vue'),
    RowSubheader: () => import('../RowSubheader.vue'),
    TextFieldDatePicker: () => import('../../input/TextFieldDatePicker.vue'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    config: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    eventGroups: [],        // Set to default values on mount and dialog close.
    scrollable: false,
    rules: {
      required: v => !!v || 'Value is required.',
      length50: v => (v || '').length <= 50 || 'Must have 50 characters or fewer.',
      length250: v => (v || '').length <= 250 || 'Must have 250 characters or fewer.',
    },
    selectedColor: 0,       // What color this event is.
    selectedEventGroup: [], // What event set is selected in the dialog window.
    selectedEventType:  [], // What event type is selected in the dialog window.
    valid: false,
    payload: {},            // The actual values set in the dialog window.
  }),
  computed: {
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    dialogTitle () {
      return `${this.isEditing ? 'Edit' : 'Add' } Event` 
    },
    editedEvent () {
      return this.config.selectedEvent
    },
    isEditing () {
      return this.config.isEditing
    },
    isXsBreakpoint () {
      return this.breakpoint === 'xs'
    },
  },
  methods: {
    addEvent () {
      this.$emit('add-event', this.payload)
      this.close()
    },
    close () {
      this.$emit('update:dialog', false)
      this.resetEventGroups()
    },
    deleteEvent () {
      this.$emit('delete-event', this.payload)
      this.close()
    },
    getSelectedTypeColor () {
      for (let i = 0; i < this.payload.typeList.length; i++) {
        if (this.selectedEventType === this.payload.typeList[i]) {
          return i
        }
      }
    },
    handleEventGroupChange () {
      const eventGroup = cloneDeep(this.selectedEventGroup)
      this.selectedEventType = eventGroup.typeList[0]

      this.payload.eventGroupType = this.selectedEventGroup.eventGroupType
      this.payload.typeList = eventGroup.typeList

      this.handleEventTypeChange()
    },
    handleEventTypeChange () {
      this.payload.eventType = this.selectedEventType
      this.selectColor(this.getSelectedTypeColor())
    },
    initEditedPayload () {
      this.payload = this.editedEvent

      this.selectedEventGroup = this.payload.eventGroupType

      const eventGroups = {
        [EVENT_GROUPS.MAGAZINES]: CalendarEventFactory.getDefaultMagazineEventGroups(),
        [EVENT_GROUPS.MATURITY_LETTERS]: CalendarEventFactory.getDefaultMaturityLetterEventGroups(),
        [EVENT_GROUPS.SEASONAL_E_MESSAGE]: CalendarEventFactory.getDefaultSeasonalEMessageEventGroups(),
        [EVENT_GROUPS.THANK_YOU_CARDS]: CalendarEventFactory.getDefaultThankYouCardsEventGroups(),
      }

      const eventGroup = eventGroups[this.selectedEventGroup]
      this.payload.typeList = eventGroup.typeList
      this.selectedEventType = this.payload.eventType

      this.selectColor(this.getSelectedTypeColor())
    },
    initTemplateConstants () {
      this.EVENT_GROUP_COLOR = EVENT_GROUP_COLOR
      this.EVENT_GROUP_COLOR_MODIFIER = EVENT_GROUP_COLOR_MODIFIER
      this.EVENT_GROUP_DARK = EVENT_GROUP_DARK
      this.COLOR_ICON = ICONS.COLOR
    },
    resetEventGroups () {
      this.eventGroups = [
        CalendarEventFactory.getDefaultMagazineEventGroups(),
        CalendarEventFactory.getDefaultMaturityLetterEventGroups(),
        CalendarEventFactory.getDefaultSeasonalEMessageEventGroups(),
        CalendarEventFactory.getDefaultThankYouCardsEventGroups(),
      ]

      this.selectedColor = 0
      this.selectedEventGroup = this.eventGroups[0]

      const eventGroup = cloneDeep(this.selectedEventGroup)
      this.selectedEventType = eventGroup.typeList[0]
      this.payload.typeList = eventGroup.typeList
      this.payload.eventGroupType = this.selectedEventGroup.eventGroupType
      this.payload.eventType = this.selectedEventType
      this.payload = eventGroup
      this.payload.name = null
      this.selectColor(this.getSelectedTypeColor())
      this.payload.description = null
      this.payload.start = today()
    },
    selectColor (index) {
      this.selectedColor = index
      this.payload.color = {
        color: `${EVENT_GROUP_COLOR[this.payload.eventGroupType]} ${EVENT_GROUP_COLOR_MODIFIER[this.payload.typeList[index]]}`,
        dark: EVENT_GROUP_DARK[this.payload.typeList[index]],
      }
    },
    setEventTitle (event, index) {
      this.payload.titleList[index] = event.replace(this.payload.titlePrefix, '')
    },
    updateEvent () {
      this.$emit('update-event', this.payload)
      this.close()
    },
  },
  watch: {
    'config.isEditing': {
      handler: function(to, from) {
        // If the new value is true, we're editing.
        if (to) {
          this.initEditedPayload()
        } else {
          this.handleEventTypeChange()
        }
      },
      deep: true
    }
  },
  beforeUpdate () {
    this.$nextTick(() => {
      const element = document.getElementsByClassName('v-dialog--active')[0]
      this.scrollable = !!element && element.scrollHeight > element.clientHeight
    })
  },
  mounted () {
    this.resetEventGroups()
  },
  created () {
    this.initTemplateConstants()
  },
}
</script>

<style scoped>
.v-badge {
  display: block !important;
}
</style>