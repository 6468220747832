<template>
  <v-card
    class="mx-auto"
    max-width="600"
    outlined
  >
    <card-toolbar
      :back-button-route="backButtonRoute"
      :back-button-tooltip="backButtonTooltip"
      :icon="NOT_FOUND_ICON"
      :title="text"
      button-mode
      class="mt-4 mb-3"
      include-back
    />
  </v-card>
</template>

<script>
import { ICONS } from '../../../constants'

export default {
  components: {
    CardToolbar: () => import('../toolbars/CardToolbar.vue'),
  },
  props: {
    backButtonTooltip: {
      type: String
    },
    backButtonRoute: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  methods: {
    initTemplateConstants () {
      this.NOT_FOUND_ICON = ICONS.NOT_FOUND
    }
  },
  created () {
    this.initTemplateConstants()
  }
}
</script>
