import FileColumnConfig, { COLUMN_TYPE_NUMERIC } from "../FileColumnConfig"
import { TYPE as AGENT_TYPE } from '../dto/Agent'

export const TYPE = {
  TRANSFER_MORTGAGE_DEAL: 'transfer_mortgage_deal',
}

export const URL = {
  [TYPE.TRANSFER_MORTGAGE_DEAL]: 'transfer-mortgage-deal',
}

// Columns needed to transfer a Mortgage Deal from one agent to another via CSV file.
export const TRANSFER_MORTGAGE_DEAL_COLUMN_CONFIG = [
  {
    alias: 'From Agent',
    columns: [
      (new FileColumnConfig('agent_id', 'from agent id', COLUMN_TYPE_NUMERIC)).requires(null).useValidRules(AGENT_TYPE.AGENT),
    ],
  },
  {
    alias: 'To Agent',
    columns: [
      (new FileColumnConfig('agent_id', 'to agent id', COLUMN_TYPE_NUMERIC)).requires(null).useValidRules(AGENT_TYPE.AGENT),
    ],
  },
]