import { includes } from 'lodash'

import CSVService, { CSV_ACTIONS, CSV_UPLOAD_TYPES } from './api/csv.service'

export default class UploadService {
  /**
   * Create a service for a data type that uploads the CSV file to a specific URL based on the data
   * type and the intended action for the file.
   * @param {string} type Type of CSV contents. 
   * @param {string} action API action to take with file.
   */
  constructor(type, action) {
    if (!includes(CSV_UPLOAD_TYPES, type)) {
      throw new Error(`"${type}" is an unknown csv type`)
    }
    if (!includes(CSV_ACTIONS, action)) {
      throw new Error(`"${action}" is an unknown action`)
    }
    this.type = type
    this.action = action
  }

  /**
   * Perform the file upload.
   * @param {*} file File being uploaded to the server. 
   * @param {string} id User ID of the user uploading the file.
   * @param {Function} callback Callback to provide progress on the file being uploaded to the server.
   * @returns The promise from the axios API call.
   */
  upload(file, id, callback) {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("user_id", id)

    return CSVService.uploadFile(this.type, this.action, formData, callback)    
  }
}